<template>
  <v-dialog v-model="isOpen" max-width="950" persistent>
    <form>

      <v-card class="mx-auto py-2" max-width="950">
        <v-card-title class="justify-start "> Manage VAs </v-card-title>
        
        <p class="note">
          Click on Checkbox <v-icon size="16">{{mdiCheckboxMarked}}</v-icon> for Assigning and Revoking VA from project.
        </p>

        <div class="main-box">

          <div class="left-box">
            <small class="sub-heading">Available VAs</small>
            <!-- <v-list>
                <v-list-item-group multiple v-model="notAvailableVAModel" v-if="userData.availableVA.length != 0">
                    <template v-for="(item, i) in userData.availableVA">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
            
                    <v-list-item v-else :key="`item-${i}`" :value="item">
                        <template v-slot:default="{ active }" >
            
                        <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text mr-4">
                            <v-img :src="item.profile_picture_url" />
                        </v-avatar>
            
                        <v-list-item-content>
                        <v-list-item-title v-text="item.username"></v-list-item-title>
                            <p>{{active}}</p>
                        </v-list-item-content>
            
                        <v-list-item-action>
                            <v-checkbox :input-value="active" color="deep-purple accent-4" @change="manageVaToClient(item.id,active,i)"></v-checkbox>
                        </v-list-item-action>
            
                        </template>
                    </v-list-item>
            
                    </template>
                </v-list-item-group>
                <p class="notFound" v-else>
                    No Available VAs
                </p>
                </v-list> -->

            <v-list>
              <template v-if="availableVAItems.length != 0">
                <v-list-item v-for="(item) in availableVAItems" :key="item.id">
                  <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text mr-4">
                    <v-img :src="item.profile_picture_url" />
                  </v-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.username"></v-list-item-title>
                    <p>{{item.username}}</p>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn text color="success" @click.prevent="assignVaToClient(item)">Assign</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <p class="notFound" v-else> No Available VAs </p>
            </v-list>
          </div>

          <div class="right-box">
            <small class="sub-heading">Assigned VAs</small>
            <!-- <v-list>
                <v-list-item-group v-model="notAvailableVAModel" multiple v-if="userData.notAvailableVA.length != 0">
                    <template v-for="(item, i) in userData.notAvailableVA">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                    <v-list-item v-else :key="`item-${i}`" :value="item" >
                        <template v-slot:default="{ active }">
                        <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text mr-4">
                            <v-img :src="item.profile_picture_url" />
                            </v-avatar>
                        <v-list-item-content>
            
                            <v-list-item-title v-text="item.username"> </v-list-item-title>
                            <p>{{active}} </p>
                        </v-list-item-content>
            
                        <v-list-item-action>
                            <v-checkbox :input-value="active" color="deep-red accent-4"  @change="manageVaToClient(item.id,active,i)"></v-checkbox>
                        </v-list-item-action>
                        </template>
                    </v-list-item>
                    </template>
                </v-list-item-group>
                <p class="notFound" v-else>
                    No Assigned VAs
                </p>
                </v-list> -->

            <v-list>
              <template v-if="notAvailableVAitems.length != 0">
                <v-list-item v-for="(item) in notAvailableVAitems" :key="item.id">
                  <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text mr-4">
                    <v-img :src="item.profile_picture_url" />
                  </v-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.username"></v-list-item-title>
                    <p>{{item.username}}</p>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn text color="error" @click.prevent="removeVaToClient(item.id)">Revoke</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <p class="notFound" v-else> No Available VAs </p>
            </v-list>

          </div>

        </div>

        <v-card-actions class="justify-end pt-10">
          <v-btn class="px-10" color="danger" @click="closeDialog"> Close </v-btn>
        </v-card-actions>

      </v-card>
    </form>
  </v-dialog>

</template>

<script>
import axios from '@axios'
import { onBeforeUpdate, onMounted, onUpdated, reactive, ref } from '@vue/composition-api'
import { mdiCheckboxMarked } from '@mdi/js';

export default {
  name: 'AssignVADialog',
  props: ['isOpen', 'userData', 'userID'],
  setup(props, { emit }) {

    const notAvailableVAitems = ref([])
    const availableVAItems = ref([])
    const notAvailableVAModel = ref([])
    const availableVAModel = ref([])
    const state = reactive({
      name: '',
      description: '',
      total_va: 0,
      status: '',
      errorMsg: '',
      isLoading: false,
    })
    const userVAData = ref(null)

    userVAData.value = props.userData

    const closeDialog = () => emit('close')
    const status = ref(['Start', 'In Progress', 'Completed', 'Stopped'])

    // async function manageVaToClient(id,active,index) {
    //     alert(`User_ID : ${props.userID} Va_ID : ${id} , Active : ${active} , Index : ${index}`)
    //   try {
    //     state.errorMsg = ''
    //     state.isLoading = true

    //     if (active) {
    //      await axios.post(`/admin/remove-va-client`,{ user_id:props.userID, va_id:id })
    //   } else {
    //     await axios.post(`/admin/assign-va-client`,{ user_id:props.userID, va_id:id })
    //   }

    //   } catch (err) {
    //     console.log('failure from function')
    //     console.log(err)
    //     // state.errorMsg = err.response.data.message;
    //   } finally {
    //     state.isLoading = false
    //     setTimeout(async ()=>{
    //       let result = await axios.post(`/admin/get-vas`,{
    //         userID:props.userID
    //       })
    //       notAvailableVAitems.value = result.data.notAvailableVA
    //       notAvailableVAModel.value = result.data.notAvailableVA
    //       availableVAItems.value = result.data.availableVA
    //     },100)
    //   }

    // }

    const assignVaToClient = async (item) => {
      try {
        state.errorMsg = ''
        state.isLoading = true
        const res = await axios.post(`/admin/assign-va-client`, { user_id: props.userID, va_id: item.id })
        await allVaOfClient(props.userID)
        if (res) {
          notAvailableVAitems.value.push(res.data)
        }
      } catch (err) {
        console.log(err)
        throw err
        // state.errorMsg = err.response.data.message;
      }
    }

    const removeVaToClient = async (id) => {
      try {
        state.errorMsg = ''
        state.isLoading = true
        await axios.post(`/admin/remove-va-client`, { user_id: props.userID, va_id: id })
        await allVaOfClient(props.userID)
      } catch (err) {
        console.log(err)
        throw err
        // state.errorMsg = err.response.data.message;
      }
    }

    const allVaOfClient = async (userID) => {
      let result = await axios.post(`/admin/get-vas`, {
        userID: userID
      })
      notAvailableVAitems.value = []
      notAvailableVAModel.value = []
      availableVAItems.value = []

      notAvailableVAitems.value.push(result.data.notAvailableVA)
      notAvailableVAModel.value.push(result.data.notAvailableVA)
      availableVAItems.value.push(result.data.availableVA)
    }

    onBeforeUpdate(() => {
      console.log('Props : ', props.userData)
      notAvailableVAitems.value = props.userData.notAvailableVA
      notAvailableVAModel.value = props.userData.notAvailableVA
      availableVAItems.value = props.userData.availableVA
    })



    return {
      availableVAItems,
      notAvailableVAitems,
      availableVAModel,
      notAvailableVAModel,
      state,
      closeDialog,
      status,
      mdiCheckboxMarked,
      // manageVaToClient,
      assignVaToClient,
      removeVaToClient,
      allVaOfClient
      //   titleRules: [v => v.length <= 25 || 'Max 25 characters'],
      //   descriptionRules: [v => v.length <= 250 || 'Max 250 characters'],

    }
  },
}
</script>

<style scoped>
.main-box {
  position: relative;
  display: flex;
  gap: 10%;
}

.main-box>.right-box {
  width: 40%;
}

.main-box>.left-box {
  width: 60%;
}

.note {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.sub-heading {
  padding-left: 20px;
  font-weight: 600;
}

.notFound {
  padding-left: 20px;
  font-size: 14px;
}
</style>
