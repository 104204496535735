<template>
  <v-dialog v-model="props.isOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>

      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Assign Virtual Assistants</v-toolbar-title>
      </v-toolbar>

      <div class="">
        <v-container>
          <v-card-text class="col-md-6">
            <v-card-title>Filter Virtual Assistants</v-card-title>

            <div class="d-flex flex-row flex-wrap">
              <v-card-text class="col-6">
                <v-text-field v-model="state.filters.username" label="VA Name" dense hide-details outlined>
                </v-text-field>
              </v-card-text>

              <v-card-text class="col-6">
                <v-text-field v-model="state.filters.email" label="VA Email" dense hide-details outlined></v-text-field>
              </v-card-text>
            </div>

            <v-card-actions>
              <v-btn @click="applyFilters" color="primary">Update Table</v-btn>
              <v-btn @click="clearFilters">Reset</v-btn>
            </v-card-actions>

          </v-card-text>
          <!-- :page="state.page" :items-per-page="state.itemsPerPage" -->
          <v-data-table :headers="tableHeaders" :items="state.tableData" v-model="selection" :single-select="false"
            :customColumns="customColumns" :loading="state.isLoading" no-data-text="No Data" :calculate-widths="true">

            <template v-slot:item.vaName="{ item }">
              <div v-show="item.username">
                <v-avatar size="30px">
                  <img :src="resolveProfilePic(item)" alt="" />
                </v-avatar>
                <span class="ml-2">{{ item.username }}</span>
              </div>
              <span v-show="!item.username">No VA</span>
            </template>

            <template v-slot:item.ssm_id="{ item }">
              <span>{{ item.ssm_employment_id }}</span>
            </template>

            <template v-slot:item.email="{ item }">
              <span>{{ item.email }}</span>
            </template>

            <template v-slot:item.manageBtn="{ item, index }">
              <v-btn color="success" text @click="assignVaToClient(item, index)" :disabled="isDisable(item.id, 'assign')">
                ASSIGN</v-btn>
              <v-btn color="error" text @click="removeVaToClient(item)" :disabled="isDisable(item.id, 'revoke')">
                REVOKE</v-btn>
            </template>

          </v-data-table>
        </v-container>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import { onMounted, onUpdated, reactive, ref } from "@vue/composition-api"
import { resolveProfilePic } from "@/helpers";
import axios from '@axios'

export default {
  props: ['isOpen', 'user', 'userID'],
  setup(props, { emit }) {
    const dialog = ref(false)
    const selection = ref([])
    const state = reactive({
      tableData: [],
      isLoading: false,
      isEditing: false,
      selected: [],
      isSubmitting: false,
      itemsPerPage: 20,
      totalItems: 0,
      page: 1,
      editTarget: {},
      filters: {
        username: "",
        email: "",
      },
    });

    onUpdated(async () => {
      console.log('props.user.notAvailableVA : ', props.user.notAvailableVA)
      state.totalItems = props.user.total
      if (props.user.notAvailableVA != null) {
        selection.value = props.user.notAvailableVA == null ? [] : props.user.notAvailableVA
      }
    })

    const tableHeaders = [
      { text: "Name", value: "vaName", sortable: false },
      { text: "SSM ID", value: "ssm_employment_id", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "", value: "manageBtn", sortable: false },
    ];

    const customColumns = [
      { slotName: "manage-btn", itemValue: "manageBtn" },
    ];

    function applyFilters() {
      state.page = 1;
      fetchTableData();
    }

    function clearFilters() {
      state.page = 1;
      state.filters.username = "";
      state.filters.email = "";
      fetchTableData();
    }

    async function fetchTableData() {
      try {
        state.isLoading = true;
        const { data } = await axios.get("/admin/virtual-assistants", {
          params: { page: state.page, ...state.filters },
        });
        if (data) console.log('Data : ', data)
        state.tableData = data;
        // state.page = parseInt(data.page);
        // state.itemsPerPage = data.itemsPerPage;
        // state.totalItems = data.total;
      } catch (err) {
        this.$confirm("Failed");
      } finally {
        state.isLoading = false;
      }
    }

    const assignVaToClient = async (item, index) => {
      try {
        state.isLoading = true
        await axios.post(`/admin/assign-va-client`, { user_id: props.userID, va_id: item.id, ssm_id: item.ssm_employment_id })
        selection.value.push(item)
        state.isLoading = false
      } catch (err) {
        console.log(err)
        state.isLoading = false
        throw err
        // state.errorMsg = err.response.data.message;
      }
    }

    const removeVaToClient = async (item) => {
      try {
        state.isLoading = true

        await axios.post(`/admin/remove-va-client`, { user_id: props.userID, va_id: item.id })
        removeFromSelection(item.id)
        state.isLoading = false
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    const isDisable = (id, type) => {
      if (type == 'assign') {
        let res = selection.value.find((item) => item.id == id)
        return res != undefined ? true : false
      }
      else {
        let res = selection.value.find((item) => item.id == id)
        return res == undefined ? true : false
      }
    }

    const removeFromSelection = (id) => {
      let index = selection.value.findIndex((item) => item.id == id)
      selection.value.splice(index, 1)
    }

    onMounted(async () => await fetchTableData())

    return {
      selection,
      dialog,
      props,
      removeFromSelection,
      resolveProfilePic,
      assignVaToClient,
      removeVaToClient,
      applyFilters,
      clearFilters,
      tableHeaders,
      customColumns,
      state,
      fetchTableData,
      isDisable
    }

  }
}
</script>