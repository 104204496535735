<template>
  <div class="d-flex flex-column px-4 py-4">
    <h2 class="mb-4 cursive-font">Assign VAs</h2>

    <table-with-filters ref="assignmentTable" :options="tableOptions" :customColumns="customColumns">

      <template v-slot:name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item)" alt="" />
          </v-avatar>
          <span class="ml-2">{{ item.username }}</span>
        </div>
      </template>

      <template v-slot:email="{ item }">
        <div>
          <span class="ml-2">{{ item.email }}</span>
        </div>
      </template>

      <template v-slot:sub-status="{ item }">
        <span class="text-capitalize">
          {{ item.type || "Not subscribed" }}
        </span>
      </template>

      <template v-slot:manage-btn="{ item }">
        <v-btn color="info" text @click="openAssignDialog(item.id)"> MANAGE</v-btn>
      </template>

    </table-with-filters>

    <AssignDialog :is-open="isAssignVaDialogOpen" :user="userData" :userID="uID" @update="updateTable(id)" @close="isAssignVaDialogOpen = false"/>

  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { resolveProfilePic } from "@/helpers";
import { useAssignment } from "@/composables/assignment/assignment.js";
import axios from "@axios";
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import AssignVADialog from "@/components/assignment/AssignVADialog.vue"
import AssignDialog from "@/components/assignment/AssignDialog.vue";

export default {
  name: "ManageAssignment",
  components: { 
    TableWithFilters, 
    AssignDialog 
  },
  setup() {

    const tableHeaders = [
      { text: "Username", value: "Username", sortable: false },
      { text: "Email", value: "Email", sortable: false },
      { text: "User Type", value: "subStatus", sortable: false },
      { text: "", value: "manageBtn", sortable: false },
    ];

    const customColumns = [
      { slotName: "name", itemValue: "Username" },
      { slotName: "email", itemValue: "Email" },
      { slotName: "sub-status", itemValue: "subStatus" },
      // { slotName: "va-name", itemValue: "vaName" },
      { slotName: "manage-btn", itemValue: "manageBtn" },
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
    ];

    const assignmentTable = ref(null);
    const isAssignVaDialogOpen = ref(false)
    const userData = ref(null)
    const uID = ref(null)
    const { setEditTarget, isEditing } = useAssignment();

    function triggerTableUpdate() {
      assignmentTable.value.fetchTableData();
    }

    function showAlert(id){
      alert(`ID : ${id.id} SD`)
    }

    async function updateTable(id){
      console.log('UpdateTable')
      try {
         let {data} = await axios.post(`/admin/get-vas`,{
            userID:id
          })
          userData.value = data
          } catch (err) {
            console.log(err)
          }
    }

    const openAssignDialog = async (id) =>{
      try {
          await updateTable(id)
          uID.value = id
          isAssignVaDialogOpen.value = !isAssignVaDialogOpen.value
          // console.log('Data ::: ',data)
          } catch (err) {
            console.log(err)
          }
    }

    return {
      assignmentTable,
      triggerTableUpdate,
      setEditTarget,
      isEditing,
      showAlert,
      uID,
      resolveProfilePic,
      isAssignVaDialogOpen,
      openAssignDialog,
      tableOptions: {
        endpoint: "/admin/clients",
        headers: tableHeaders,
        filtersTitle: "Filter Clients",
        filterFields,
      },
      customColumns,
      AssignVADialog,
      userData,
      
    };
  },
};
</script>

<style>
.v-data-footer__pagination {
  margin-left: auto !important;
}

.v-data-footer__select {
  display: none;
}
</style>
